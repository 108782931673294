import React from "react"

import Layout from "../components/layout"
import Clients from "../components/clients"
import About from "../components/about"
import SEO from "../components/seo"

import thumbnailPlan from "../../static/images/feature-4.jpg"
import thumbnailBoard from "../../static/images/feature-1.jpg"
import thumbnailNews from "../../static/images/feature-8.jpg"

const IndexPage = () => (
  <Layout lang="en">
    <SEO title="Outdoor Media Integrator (OMI)" />
    <div id="omi" className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Event"} src={thumbnailPlan} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>OMI Platform</h2>
                <ul>
                  <li>
                    The solution which allows to set a role of the billboards
                    location under the process of planning and optimization of
                    OOH campaigns
                  </li>
                  <li>
                    Geotargeting campaign due to the location POI (places) from
                    the application OMI or own places from customers
                  </li>
                  <li>
                    Powerful tool which gives an access to many offers from
                    different OOH providers at one application, which are
                    presented by table, maps, heat map and reports
                  </li>
                  <li>Dynamic presentation for a customers as a web page</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Flexibility</h2>
                <p>
                  Thanks to cooperation with media houses and suppliers of
                  outdoor advertising, OMI is constantly developed, modified and
                  integrated with solutions on the user's side. This makes OMI
                  an integral element of solutions for the planning and
                  optimization of outdoor advertising, OOH and DOOH as well.
                </p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Board"} src={thumbnailBoard} />
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"News"} src={thumbnailNews} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Cooperation and development</h2>
                <p>
                  Cooperation with external entities, integrators and data
                  providers made itpossible, that OMI has become standard as a
                  tools in the work of media planners of outdoor advertising.It
                  gives a new possibilities in building and optimizing the
                  selection of OOH advertising.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Clients lang="en" />

    <About lang="en" />
  </Layout>
)

export default IndexPage
